// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-crabs-js": () => import("./../../../src/pages/crabs.js" /* webpackChunkName: "component---src-pages-crabs-js" */),
  "component---src-pages-games-crabs-js": () => import("./../../../src/pages/games/crabs.js" /* webpackChunkName: "component---src-pages-games-crabs-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-under-construction-js": () => import("./../../../src/pages/under-construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */),
  "component---src-templates-crab-page-template-js": () => import("./../../../src/templates/crab-page-template.js" /* webpackChunkName: "component---src-templates-crab-page-template-js" */)
}

